body {
  margin: 0;
  font-family: Inter-Regular, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  overflow: hidden;
  font-size: 14px;
  color: #e4e4e6;
}

.MuiTooltip-popper * {
  font-family: Inter-Regular, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

#root {
  height: 100vh;
  overflow: hidden;
}

body:not(.launcher_root) {
  height: 100vh;
  overflow-y: hidden;
}

/** >> BASIC CSS **/

p {
  margin: 0;
}

h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 3px 0;
}

h2 {
  font-size: 17px;
}

h3 {
  font-size: 16px;
}

a,
a:visited {
  color: inherit;
}
a:-webkit-any-link {
  text-decoration: none;
}

:focus {
  outline: none;
}

pre {
  overflow-x: auto;
  white-space: normal;
  word-wrap: break-word;
}

input {
  width: 100%;
}

textarea,
input,
input:focus,
textarea:focus,
button:focus {
  border: none;
  outline: none;
  outline-offset: 0;
}

ul {
  display: block;
  list-style-type: none;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-inline-start: 0;
}

mark {
  color: unset;
}

.simplebar-scrollbar:before {
  width: 4px;
}

.simplebar-content-wrapper {
  outline: none;
}

:focus-visible {
  outline: none;
}

::-webkit-scrollbar {
  display: none;
}
